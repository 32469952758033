import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import * as serviceWorker from './utils/serviceWorker';

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import rootReducer from "./redux/";

import 'babel-polyfill' //adds promises if they are missing
import thunkMiddleware from 'redux-thunk' //used for async api calls

//import { createLogger } from 'redux-logger'
//const loggerMiddleware = createLogger()

const store = createStore(rootReducer,applyMiddleware(
    thunkMiddleware, // lets us dispatch() functions
    //loggerMiddleware // neat middleware that logs actions
  ))

ReactDOM.render(
  <Provider store={store}>
  <App />
  </Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
