var Session = (function() {
  var user = false;

  var getSession = function() {
    user=localStorage.getItem('user');
    if(!user || user==='false'){
      return(false)
    }else{
      return(JSON.parse(user));
    }
  };

  var setSession = function(data) {
    localStorage.setItem('user', JSON.stringify(data));
    user = data;
    // Also set this in cookie/localStorage
  };

  var clearSession = function(name) {
    localStorage.setItem('user', false);
    user = false;
    // Also set this in cookie/localStorage
  };

  return {
    getSession: getSession,
    setSession: setSession,
    clearSession: clearSession
  }

})();

export default Session
;
