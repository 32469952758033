const initialState = {
  user: null
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('user', JSON.stringify(action.payload));
      return {
        ...state,
        user: action.payload,
      }
    case 'LOGOUT':
      localStorage.setItem('user', null);
      return {
        ...state,
        user: null,
      }
    case 'VERIFY_LOCAL':
      //localStorage.clear()
      var user=localStorage.getItem('user');
      if(!user || user==='' || user==='undefined' || user==='null'){
      	      user=null;
      }else{
      	    user=JSON.parse(user);
      }
      return {
        ...state,
        user: user,
      }
    default:
      return state
  }
}

export default appReducer
