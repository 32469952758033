import React from 'react';

import HashLoader from 'react-spinners/HashLoader';


class Spinner extends React.Component {
  render() {
  	  return(
		<div style={{textAlign:'center',paddingTop:(window.pageYOffset+300)+'px',paddingLeft:'50%',opacity:'0.8',position:'absolute',top:'0',left:'0',zIndex:'999999999',height:window.innerHeight+'px',width:'100vw',background:'white'}}>
			<div style={{textAlign:'center',position:'relative',top:'0px',left:'-40px'}}>
			<HashLoader
			size={80}
			color={'#ccc'}
			loading='true'
			/>
			</div>
		</div>
  	);
  }
}
export default Spinner;
