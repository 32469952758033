import React from 'react';
import { connect } from 'react-redux';
import { Button, Carousel } from 'react-bootstrap';

import { setSplashSeenAction } from '../redux/app/actions';

class SplashScreens extends React.Component {
  
  handleClose = () => {
  	  const { setSplashSeen } = this.props
  	  setSplashSeen() 
  }
  
  render() {

    return (
    	<div style={{position:'absolute',top:'0px',left:'0px',width:'100%',height:window.innerHeight+'px',zIndex:'99999991'}}>
        <Carousel style={{position:'absolute',top:'0px',left:'0px',width:'100%',height:window.innerHeight+'px',zIndex:'99999991'}}>
	      <Carousel.Item>
	      	<div style={{backgroundImage:'url(https://ms1.marketsorcery.com/portal/ms1/custom_modules/img/splash1.jpg)',backgroundSize:'cover',width:'100%',height:window.innerHeight+'px'}}></div>
		<Carousel.Caption>
		  <h3>First slide label</h3>
		  <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
		</Carousel.Caption>
	      </Carousel.Item>
	      <Carousel.Item>
		<div style={{backgroundImage:'url(https://ms1.marketsorcery.com/portal/ms1/custom_modules/img/splash2.jpg)',backgroundSize:'cover',width:'100%',height:window.innerHeight+'px'}}></div>
		<Carousel.Caption>
		  <h3>Second slide label</h3>
		  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
		</Carousel.Caption>
	      </Carousel.Item>
	      <Carousel.Item>
		<div style={{backgroundImage:'url(https://ms1.marketsorcery.com/portal/ms1/custom_modules/img/splash3.jpg)',backgroundSize:'cover',width:'100%',height:window.innerHeight+'px'}}></div>
		<Carousel.Caption>
		  <h3>Third slide label</h3>
		  <p>
		    Praesent commodo cursus magna, vel scelerisque nisl consectetur.
		  </p>
		</Carousel.Caption>
	      </Carousel.Item>
	    </Carousel>
	    <Button style={{zIndex:'99999992',position:'absolute',bottom:'20px',right:'20px'}} className="btn btn-info" onClick={this.handleClose}>Close</Button>
	 </div>
    );
  }
  
}

function mapStateToProps(state) {
  return {
    splashSeen: state.app.splashSeen
  };
}
const mapDispatchToProps = dispatch => ({
  setSplashSeen: () => dispatch(setSplashSeenAction())
})

export default connect(mapStateToProps,mapDispatchToProps)(SplashScreens);
