const initialState = {
	server:{}
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SETTINGS':
      return {
        ...state,
        server: action.payload,
      }
    default:
      return state
  }
}

export default appReducer
