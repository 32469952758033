
const initialState = {
  isSidebarOpen: false,
  settingsLoaded: false,
  splashSeen: false,
  modalData: null,
  loadingMessage: null
}

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MODAL':
      return {
        ...state,
        modalData: action.payload,
      }
    case 'CLEAR_MODAL':
      return {
        ...state,
        modalData: null,
      }
    case 'SET_LOADING':
      return {
        ...state,
        loadingMessage: action.payload,
      }
    case 'CLEAR_LOADING':
      return {
        ...state,
        modalMessage: null,
      }
    case 'SPLASH_SEEN':
      return {
        ...state,
        splashSeen: true,
      }
    case 'SETTINGS_LOADED':
      return {
        ...state,
        settingsLoaded: true,
      }
    case 'SIDEBAR_OPEN':
      return {
        ...state,
        isSidebarOpen: true,
      }
    case 'SIDEBAR_CLOSE':
      return {
        ...state,
        isSidebarOpen: false,
      }
    default:
      return state
  }
}

export default appReducer
