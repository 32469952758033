import React from 'react';
import { Nav, Navbar} from 'react-bootstrap';
import { FiShoppingBag, /*FiHome,*/ FiHeart, FiMenu } from 'react-icons/fi';

import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {sidebarOpenAction,sidebarCloseAction} from '../redux/app/actions';

class Footer extends React.Component {
  
  goHome = () => {
    this.props.history.push('/')
  }

  goShop = () => {
    this.props.history.push('/shop')
  }

  goSaved = () => {
    this.props.history.push('/saved')
  }

  goSidebar = () => {
    const {sidebarOpen} = this.props;
    sidebarOpen()
  }

  render() {
    return(
        <Navbar fixed="bottom">
            <Navbar.Collapse className="justify-content-center">
              <Nav.Link onClick={this.goHome} style={{textAlign:'center'}}><img src="https://ms1.marketsorcery.com/portal/ms1/custom_modules/img/logo_square.jpg" alt="Logo" style={{width:'60px'}}/></Nav.Link>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-center">
              <Nav.Link onClick={this.goShop} style={{textAlign:'center'}}><FiShoppingBag style={{fontSize:'24pt'}}/><div style={{fontSize:'8pt',textAlign:'center'}}>Shop</div></Nav.Link>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-center">
              <Nav.Link onClick={this.goSaved} style={{textAlign:'center'}}><FiHeart style={{fontSize:'24pt'}}/><div style={{fontSize:'8pt',textAlign:'center'}}>Saved</div></Nav.Link>
            </Navbar.Collapse>
            <Navbar.Collapse className="justify-content-center">
              <Nav.Link onClick={this.goSidebar} style={{textAlign:'center'}}><FiMenu style={{fontSize:'24pt'}}/><div style={{fontSize:'8pt',textAlign:'center'}}>More</div></Nav.Link>
          </Navbar.Collapse>
      </Navbar>
    )
  }

}

const mapStateToProps = state => ({
    isSideBarOpen: state.app.isSideBarOpen
})

const mapDispatchToProps = dispatch => bindActionCreators({
    sidebarOpen: sidebarOpenAction,
    sidebarClose: sidebarCloseAction
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Footer));
