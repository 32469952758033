import React from 'react';

import { connect } from 'react-redux';

import {
  Button,
  Form,
} from 'react-bootstrap';

import { withRouter } from 'react-router-dom';

import { loginAction, logoutAction, signupAction } from '../redux/auth/actions';
import { setModalAction } from '../redux/app/actions';


class Login extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
			login_username: '',
			login_password: '',
			first_name: '', 
			last_name: '', 
			email: '', 
			password: ''  
		};
		this.handleInputChange = this.handleInputChange.bind(this);
	}
	
	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.setState({[name]: value});
	}

	doLogin = () => {
		const { login, setModal } = this.props
		if(this.state.login_username==='' || !this.state.login_username){
			setModal({title: 'Form incomplete', message: 'Please enter an email address.', type: 'negative'})
			return false
		}
		if(this.state.login_password==='' || !this.state.login_password){
			setModal({title: 'Form incomplete', message: 'Please enter a password.', type: 'negative'})
			return false
		}
		login(this.state.login_username,this.state.login_password);
	}
	
	doLogout = () => {
		const { logout } = this.props
		logout();
	}
	
	doSignup = () => {
		const { signup, setModal } = this.props
		if(this.state.first_name==='' || !this.state.first_name){
			setModal({title: 'Form incomplete', message: 'Please enter your first name.', type: 'negative'})
			return false
		}
		if(this.state.last_name==='' || !this.state.last_name){
			setModal({title: 'Form incomplete', message: 'Please enter your last name.', type: 'negative'})
			return false
		}
		if(this.state.email==='' || !this.state.email){
			setModal({title: 'Form incomplete', message: 'Please enter your email.', type: 'negative'})
			return false
		}
		if(this.state.password==='' || !this.state.password){
			setModal({title: 'Form incomplete', message: 'Please enter a password.', type: 'negative'})
			return false
		}
		signup(this.state.first_name,this.state.last_name,this.state.email,this.state.password);
	}

  render(){
    return(
    	    (this.props.user?
    	    	    <div>
    	   	 	{this.props.user.first_name}
    	   	 	{this.props.user.last_name}
    	   	 	{this.props.user.email}
    	   	 	
    	   	 	<Button variant="primary" type="button" onClick={this.doLogout} >
    	   	 	Logoff
    	   	 	</Button>
    	   	 	
    	    	    </div>
    	    	    :
		    <div>
			    <h1>Login:</h1>
			    
			    <Form>
				    <Form.Group>
					    <Form.Label>Email address</Form.Label>
					    <Form.Control autoComplete='off' type="email" placeholder="Enter email"  name="login_username" onChange={this.handleInputChange}/>
				    </Form.Group>
				    
				    <Form.Group>
					    <Form.Label>Password</Form.Label>
					    <Form.Control autoComplete='off' type="password" placeholder="Enter password" name="login_password" onChange={this.handleInputChange}/>
				    </Form.Group>
				    
				    <Button variant="primary" type="button" onClick={this.doLogin} >
					Login
				    </Button>
			    </Form>
			    
			    <hr/>
			    
			    <h1>Sign Up:</h1>
			    
			    <Form>
				    <Form.Group>
					    <Form.Label>First Name</Form.Label>
					    <Form.Control autoComplete='off' type="text" placeholder="Enter first name"  name="first_name" onChange={this.handleInputChange}/>
				    </Form.Group>
				    
				    <Form.Group>
					    <Form.Label>Last Name</Form.Label>
					    <Form.Control autoComplete='off' type="text" placeholder="Enter last name"  name="last_name" onChange={this.handleInputChange}/>
				    </Form.Group>
				    
				    <Form.Group>
					    <Form.Label>Email address</Form.Label>
					    <Form.Control autoComplete='off' type="email" placeholder="Enter email"  name="email" onChange={this.handleInputChange}/>
				    </Form.Group>
				    
				    <Form.Group>
					    <Form.Label>Password</Form.Label>
					    <Form.Control autoComplete='off' type="password" placeholder="Enter password" name="password" onChange={this.handleInputChange}/>
				    </Form.Group>
				    
				    <Button variant="primary" type="button" onClick={this.doSignup} >
					Signup
				    </Button>
			    </Form>
		    </div>
	    )
      )
    }

}

const mapStateToProps = state => ({
    user: state.auth.user
})
const mapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch(loginAction(email, password)),
  logout: () => dispatch(logoutAction()),
  signup: (first_name, last_name, email, password) => dispatch(signupAction(first_name, last_name, email, password)),
  setModal: (message) => dispatch(setModalAction(message))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Login));
