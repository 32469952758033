import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';

import Menu from './nav/menu.js';
import Footer from './nav/footer.js';
import SideBar from './nav/sidebar';

import Home from './pages/home.js';
import Login from './pages/login.js';
import Register from './pages/register.js';
import Cart from './pages/cart.js';
import Shop from './pages/shop.js';
import Saved from './pages/saved.js';
import Account from './pages/account.js';
import Settings from './pages/settings.js';
import Privacy from './pages/privacy.js';
import Terms from './pages/terms.js';
import Returns from './pages/returns.js';
import About from './pages/about.js';
import Contact from './pages/contact.js';
import NotFound from './pages/not_found.js';

import Spinner from './components/spinner.js';
import GenericModal from './components/modal.js';
import SplashScreens from './components/splashScreens.js';
import SettingsLoader from './components/settingsLoader.js';

import { verifyLocalUserAction } from './redux/auth/actions';

class App extends React.Component {
	
	constructor(props) {
		super(props);
		const { verifyLocalUser } = this.props
		verifyLocalUser();
	}
	
	render(){
		return (
		    <Router basename={'/app'}>
		      <SideBar/>
		      {this.props.settingsLoaded===false?
		      <SettingsLoader/>
		      :
			    (this.props.splashSeen===false?
			    <SplashScreens/>
			    :
			    <Fragment>
			    <Menu/>
			    <Container fluid style={{marginTop:'100px',marginBottom:'100px'}}>
				<Switch>
				    <Route exact path='/' component={Home} />
				    <Route path='/login' component={Login} />
				    <Route path='/register' component={Register} />
				    <Route path='/cart' component={Cart} />
				    <Route path='/shop' component={Shop} />
				    <Route path='/saved' component={Saved} />
				    <Route path='/account' component={Account} />
				    <Route path='/settings' component={Settings} />
				    <Route path='/privacy' component={Privacy} />
				    <Route path='/terms' component={Terms} />
				    <Route path='/returns' component={Returns} />
				    <Route path='/about' component={About} />
				    <Route path='/contact' component={Contact} />
				    <Route path='' component={NotFound} />
				</Switch>
			    </Container>
			    <Footer/>
			    </Fragment>
			    )
		    }
		    {this.props.modalData && <GenericModal/>}
		    {this.props.loadingMessage && <Spinner/>}
		    </Router>
		  );
	}
}


function mapStateToProps(state) {
  return {
    loadingMessage: state.app.loadingMessage,
    modalData: state.app.modalData,
    splashSeen: state.app.splashSeen,
    settingsLoaded: state.app.settingsLoaded,
    user: state.auth.user
  };
}
const mapDispatchToProps = dispatch => ({
  verifyLocalUser: () => dispatch(verifyLocalUserAction())
})

export default connect(mapStateToProps,mapDispatchToProps)(App);
