import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';

class GenericModal extends React.Component {
  
  handleClose = () => {
  	  this.props.dispatch({ type: "CLEAR_MODAL", payload: null });  
  }
  
  render() {
    return (
        <Modal show={this.props.modalData?true:false} onHide={this.handleClose} animation={false}>
          <Modal.Header className={this.props.modalData.type==='positive'?"alert-success":(this.props.modalData.type==='negative'?"alert-danger":"")} closeButton>
            <Modal.Title>{this.props.modalData.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{this.props.modalData.message}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button className={this.props.modalData.type==='positive'?"btn-success":(this.props.modalData.type==='negative'?"btn-danger":"")} onClick={this.handleClose}>Close</Button>
          </Modal.Footer>
        </Modal>
    );
  }
  
}
function mapStateToProps(state) {
  return {
    modalData: state.app.modalData
  };
}

export default connect(mapStateToProps)(GenericModal);
