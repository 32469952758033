import React from 'react';
import { /*Nav,*/ Navbar} from 'react-bootstrap';
import { FiShoppingCart, FiArrowLeft } from 'react-icons/fi';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

class Menu extends React.Component {

  goToCart = () => {
    this.props.history.push('/cart')
  }

  goHome = () => {
    this.props.history.push('/')
  }

  goBack = () => {
      this.props.history.goBack()
  }

  render() {
      return (
      	  <div>
          <Navbar fixed="top">

            <Navbar.Collapse className="justify-content-end" style={{marginTop:'15px',textAlign:'left'}}>
              {this.props.history.goBack && <span onClick={this.goBack}><FiArrowLeft style={{fontSize:'24pt'}}/></span>}
            </Navbar.Collapse>

            <Navbar.Collapse className="justify-content-center" style={{width:'100%',marginTop:'15px',textAlign:'center'}}>
              <img src="https://ms1.marketsorcery.com/portal/ms1/custom_modules/img/logo_horizontal.jpg" alt="Logo"/>
            </Navbar.Collapse>

            <Navbar.Collapse className="justify-content-end" style={{marginTop:'15px',textAlign:'right'}}>
              <span onClick={!this.props.user?()=>{return false}:this.goToCart}><FiShoppingCart style={{fontSize:'24pt',color:(!this.props.user?'transparent':'')}}/></span>
            </Navbar.Collapse>
            
          </Navbar>
          </div>
      )
  }
}
//export default withRouter(Menu);
function mapStateToProps(state) {
  return {
    user: state.auth.user
  };
}

/*const mapDispatchToProps = {
  getSession,
  setSession,
  delSession
};*/

export default connect(mapStateToProps/*,mapDispatchToProps*/)(withRouter(Menu));
