import { sendRequest } from '../../utils/api'

export const getSettingsAction = () => {
  return async (dispatch) => {
    dispatch({ type: 'SET_LOADING', payload: 'Loading settings...' })
    const response = await sendRequest('app_load_content', {})
    dispatch({ type: 'SET_LOADING', payload: null })
    if (response.error) {
    	    console.log(response)
      dispatch({ type: 'SET_MODAL', payload: {message: 'Load settings failed: '+response.error, type: 'negative'} })
      return
    }
    dispatch({ type: 'SETTINGS_LOADED', payload: null })
    dispatch({ type: 'SET_SETTINGS', payload: response })
  }
}
