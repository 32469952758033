import React from 'react';
import { connect } from 'react-redux';

import {getSettingsAction} from '../redux/settings/actions';

class SettingsLoader extends React.Component {
	constructor(props) {
		super(props);
		const { getSettings } = this.props
		getSettings();
	}
	
	render() {
		return (
			<div style={{backgroundImage:'url(https://ms1.marketsorcery.com/portal/ms1/custom_modules/img/settings_loader.jpg)',backgroundSize:'cover',position:'absolute',top:'0px',left:'0px',width:'100%',height:window.innerHeight+'px',zIndex:'9'}}>
			</div>
			);
	}
  
}

function mapStateToProps(state) {
  return {
    settingsLoaded: state.app.settingsLoaded
  };
}
const mapDispatchToProps = dispatch => ({
  getSettings: () => dispatch(getSettingsAction())
})

export default connect(mapStateToProps,mapDispatchToProps)(SettingsLoader);
