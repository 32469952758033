import { combineReducers } from 'redux'

import app from './app'
import auth from './auth'
import settings from './settings'

const rootReducer = combineReducers({
  app,
  auth,
  settings,
})

export default rootReducer
