import { sendRequest } from '../../utils/api'

export const loginAction = (login_email, login_password) => {
  return async (dispatch) => {
    dispatch({ type: 'SET_LOADING', payload: 'Verifying login...' })
    const response = await sendRequest('app_login', {
      login_username: login_email,
      login_password: login_password,
    })
    dispatch({ type: 'SET_LOADING', payload: null })
    console.log(response)
    if (response.error) {
      dispatch({ type: 'SET_MODAL', payload: {message: 'Login failed: '+(response.message?response.message:''), type: 'negative'} })
      return
    }
    dispatch({ type: 'LOGIN', payload: response })
    dispatch({ type: 'SET_MODAL', payload: {message: 'Login successful.', type: 'positive'} })
  }
}

export const logoutAction = (email, password) => {
  return async (dispatch) => {
    /*
    dispatch({ type: 'SET_LOADING', payload: 'Logging out...' })
    const response = await sendRequest('app_logoff', {})
    dispatch({ type: 'SET_LOADING', payload: null })
    if (response.error) {
      dispatch({ type: 'SET_MODAL', payload: {message: 'Logout failed: '+(response.message?response.message:''), type: 'negative'} })
      return
    }
    */
    dispatch({ type: 'LOGOUT', payload: null })
    //dispatch({ type: 'SET_MODAL', payload: {message: 'Logout successful.', type: 'positive'} })
  }
}

export const signupAction = (first_name, last_name, email, password) => {
  return async (dispatch) => {
    dispatch({ type: 'SET_LOADING', payload: 'Verifying signup...' })
    const response = await sendRequest('app_signup', {
      first_name:first_name, 
      last_name:last_name, 
      email:email, 
      password:password
    })
    dispatch({ type: 'SET_LOADING', payload: null })
    console.log(response)
    if (response.error) {
      dispatch({ type: 'SET_MODAL', payload: {message: 'Signup failed: '+(response.message?response.message:''), type: 'negative'} })
      return
    }
    dispatch({ type: 'SET_MODAL', payload: {message: 'Signup successful.', type: 'positive'} })
  }
}

export const verifyLocalUserAction = () => {
  return async (dispatch) => {
    /*
    dispatch({ type: 'SET_LOADING', payload: 'Logging out...' })
    const response = await sendRequest('app_logoff', {})
    dispatch({ type: 'SET_LOADING', payload: null })
    if (response.error) {
      dispatch({ type: 'SET_MODAL', payload: {message: 'Logout failed: '+(response.message?response.message:''), type: 'negative'} })
      return
    }
    */
    dispatch({ type: 'VERIFY_LOCAL', payload: null })
  }
}
