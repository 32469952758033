import React from 'react';
import { connect } from 'react-redux';

//import { getSession, setSession, delSession } from './redux/actions';

class Home extends React.Component {
  render(){
    //console.log(this.props)
    return(
      <div>
        <div>
          <h1>Home</h1>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth.user
  };
}

/*const mapDispatchToProps = {
  getSession,
  setSession,
  delSession
};*/

export default connect(mapStateToProps/*,mapDispatchToProps*/)(Home);
