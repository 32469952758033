
export const setModalAction = message => ({ type: 'SET_MODAL', payload: message })
export const clearModalAction = () => ({ type: 'CLEAR_MODAL' })

export const setLoadingAction = message => ({ type: 'SET_LOADING', payload: message })
export const clearLoadingAction = () => ({ type: 'CLEAR_LOADING' })

export const setSplashSeenAction = () => ({ type: 'SPLASH_SEEN', payload: null })

export const setSettingsLoadedAction = () => ({ type: 'SETTINGS_LOADED', payload: null })

export const sidebarOpenAction = () => ({ type: 'SIDEBAR_OPEN', payload: null })
export const sidebarCloseAction = () => ({ type: 'SIDEBAR_CLOSE', payload: null })

