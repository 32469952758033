import React from 'react';

import { withRouter } from 'react-router-dom';

class Account extends React.Component {

  goLogin = () => {
    this.props.history.push('/login')
  }

  render() {
    return(
      <div>
      <h1>Account</h1>
      <button className="btn btn-lg btn-warning" onClick={this.goLogin} >Login</button>
      </div>
    )
    
  }

}
export default withRouter(Account);
