import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { withRouter } from 'react-router-dom';

import { slide as Menu } from 'react-burger-menu';

import {sidebarOpenAction,sidebarCloseAction} from '../redux/app/actions';

import { FiUser, FiSettings, FiFileText, FiBookOpen, FiSend, FiTruck, FiMessageCircle } from 'react-icons/fi';

class SideBar extends Component {
	
	goAccount = () => {
		 const {sidebarClose} = this.props;
		this.props.history.push('/account')
		sidebarClose()
	}
	
	goSettings = () => {
		 const {sidebarClose} = this.props;
		this.props.history.push('/settings')
		sidebarClose()
	}
	
	goPrivacy = () => {
		 const {sidebarClose} = this.props;
		this.props.history.push('/privacy')
		sidebarClose()
	}
	
	goTerms = () => {
		 const {sidebarClose} = this.props;
		this.props.history.push('/terms')
		sidebarClose()
	}
	
	goReturns = () => {
		 const {sidebarClose} = this.props;
		this.props.history.push('/returns')
		sidebarClose()
	}
	
	goAbout = () => {
		 const {sidebarClose} = this.props;
		this.props.history.push('/about')
		sidebarClose()
	}
	
	goContact = () => {
		 const {sidebarClose} = this.props;
		this.props.history.push('/contact')
		sidebarClose()
	}
	
	 render(){	
	 	 const {isSidebarOpen,sidebarClose,sidebarOpen} = this.props;
	 	 
	 	 var returnStateChange = function(state) {
	 	 	 if(state.isOpen){
	 	 	 	 sidebarOpen()
	 	 	 }else{
	 	 	 	 sidebarClose()
	 	 	 }
	 	 };
	 	 
	 	 var styles = {
		  bmBurgerButton: {
		    position: 'fixed',
		    width: '36px',
		    height: '30px',
		    left: '36px',
		    top: '36px'
		  },
		  bmBurgerBars: {
		    background: '#373a47'
		  },
		  bmBurgerBarsHover: {
		    background: '#a90000'
		  },
		  bmCrossButton: {
		    height: '24px',
		    width: '24px'
		  },
		  bmCross: {
		    background: '#bdc3c7'
		  },
		  bmMenuWrap: {
		    position: 'fixed',
		    top: '0px',
		    left: '0px',
		    height: '100%',
		    zIndex: '999999999'
		  },
		  bmMenu: {
		    background: '#373a47',
		    padding: '2.5em 1.5em 0',
		    fontSize: '1.15em'
		  },
		  bmMorphShape: {
		    fill: '#373a47'
		  },
		  bmItemList: {
		    color: '#b8b7ad',
		    padding: '0.8em',
		  },
		  bmItem: {
		    display: 'block',
		    marginBottom: '30px',
		    cursor: 'pointer',
		    outline: 0,
		    border: 'none',
		    color: 'white'
		  },
		  bmOverlay: {
		    position: 'fixed',
		    top: '0px',
		    left: '0px',
		    background: 'rgba(0, 0, 0, 0.3)',
		    zIndex: '999999998'
		  }
		}

		  return (
		    <Menu styles={styles} isOpen={isSidebarOpen} onStateChange={returnStateChange} customBurgerIcon={false} disableAutoFocus >
		
		      <div className="menu-item" onClick={this.goAccount}>
			<FiUser style={{fontSize:'16pt'}}/> Account
		      </div>
		
		      <div className="menu-item" onClick={this.goSettings}>
			<FiSettings style={{fontSize:'16pt'}}/> Settings
		      </div>
		      
		      <div className="menu-item" onClick={this.goPrivacy}>
			<FiFileText style={{fontSize:'16pt'}}/> Privacy Policy
		      </div>
		      
		      <div className="menu-item" onClick={this.goTerms}>
			<FiBookOpen style={{fontSize:'16pt'}}/> Terms & Conditions
		      </div>
		      
		      <div className="menu-item" onClick={this.goReturns}>
			<FiTruck style={{fontSize:'16pt'}}/> Delivery & Returns
		      </div>
		      
		      <div className="menu-item" onClick={this.goAbout}>
			<FiMessageCircle style={{fontSize:'16pt'}}/> About Us
		      </div>
		      
		      <div className="menu-item" onClick={this.goContact}>
			<FiSend style={{fontSize:'16pt'}}/> Contact Us
		      </div>
		      
		      <div className="menu-item" style={{fontSize:'10pt',textAlign:'right',marginTop:'60px'}}>
			Version : 0.0.1
		      </div>
		      
		    </Menu>
		  );
	 }
};

const mapStateToProps = state => ({
    isSidebarOpen: state.app.isSidebarOpen
})

const mapDispatchToProps = dispatch => bindActionCreators({
    sidebarOpen: sidebarOpenAction,
    sidebarClose: sidebarCloseAction
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(SideBar));
