import React from 'react';

import Session from '../utils/session.js';

class Cart extends React.Component {

  render() {

    Session.clearSession();

    return(
      <div>
      <h1>Cart</h1>
      </div>
    )
  }

}
export default Cart
