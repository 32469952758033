import axios from 'axios'

/*
Posts to https://picton.blueboxonline/?api

Example data parameter:
{
  session_id,
  sale_id,
  files: [
    { file: file, filename: 'signature.png' },
  ]
}

qs is extra querystring parts appended as `&{$qs}`.
*/
export const sendRequest = async (api_method, data = null, qs = null) => {

  /*
  const formData = new FormData()

  formData.append('apikey', '1')
  formData.append('apisecret', '2')
  formData.append('api_method', '3')
  
  if (data) {
    Object.keys(data).forEach(k => {
      if (k === 'files') {
        data[k].forEach((f, i) => {
          formData.append(`global[files][${i}]`, f.file, f.filename)
        })
      } else if (k === 'hpemb' ) {
        Object.keys(data[k]).forEach(kk => {
          if (typeof data[k][kk] === 'object' && data[k][kk].hasOwnProperty('rows')) {
            // has rows
            data[k][kk].rows.forEach((r, i) => {
              Object.keys(r).forEach(property => {
                formData.append(`hpemb[${kk}][rows][${i}][${property}]`, r[property])
              })
            })
          } else {
            formData.append(`hpemb[${kk}]`, data[k][kk])
          }
        })
      } else if (k === 'lines') {
        data[k].forEach((l, i) => {
          formData.append(`lines[${i}][_id]`, l._id)
          formData.append(`lines[${i}][qty_despatchedNUM]`, l.qty_despatchedNUM)
        })
      } else {
        let value = data[k]
        formData.append(k, value)
      }
    })
  }
  */

  const url = 'https://ms1.marketsorcery.com/?'+api_method

  //console.log(url)
  //console.log(data)
  
  data.api_method=api_method
  
  try {
    const response = await axios.post(
      url,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    // console.log(response)
    return response.data
  } catch (e) {
    if (e.response) {
      return {
        error: true,
        message: e.response.data.error,
        status: e.response.status,
      }
    } else if (e.request) {
      return {
        error: true,
        // message: e.request,
        message: 'The request was made but no response was received.',
      }
    } else {
      return {
        error: true,
        message: e.message,
      }
    }
  }
}
