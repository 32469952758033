
import React, { Component } from 'react';
//import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {getSettingsAction} from '../redux/settings/actions';

class Saved extends Component {
    constructor(props) {
        super(props);
        this.shouldComponentRender = this.shouldComponentRender.bind(this);
    }

    componentWillMount() {
        const {getSettings} = this.props;
        getSettings();
    }

    
    shouldComponentRender() {
        //const {pending} = this.props;
        //if(this.pending === false) return false;
        // more tests
        return true;
    }
    

    render() {
        const {settings} = this.props;
 
        return (
        	<div>
        		<h1>Saved</h1>
        		
        		<ul>
        		{
				(settings.server && !settings.server.app_content) && <li>Loading...</li> 
        		}
        		{
				(settings && settings.server.app_content) && settings.server.app_content.map((item, key) =>
				    <li key={item._id}>{item.name}</li>
				)
        		}
        		</ul>
        		
        	</div>
        )
    }
}


const mapStateToProps = state => ({
    settings: state.settings
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getSettings: getSettingsAction
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Saved);
